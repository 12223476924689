import React, { createContext, useContext, useState, useCallback } from 'react';
import { StartupSubmission, SubmissionStatus } from '../types/submission';

interface SubmissionsContextType {
  submissions: StartupSubmission[];
  addSubmission: (submission: Omit<StartupSubmission, 'id' | 'status' | 'submittedAt'>) => void;
  updateSubmissionStatus: (id: string, status: SubmissionStatus, notes?: string) => void;
  getPendingSubmissions: () => StartupSubmission[];
  getApprovedSubmissions: () => StartupSubmission[];
}

const SubmissionsContext = createContext<SubmissionsContextType | undefined>(undefined);

export function SubmissionsProvider({ children }: { children: React.ReactNode }) {
  const [submissions, setSubmissions] = useState<StartupSubmission[]>([]);

  const addSubmission = useCallback((newSubmission: Omit<StartupSubmission, 'id' | 'status' | 'submittedAt'>) => {
    const submission: StartupSubmission = {
      ...newSubmission,
      id: Math.random().toString(36).substr(2, 9),
      status: 'pending',
      submittedAt: new Date().toISOString(),
    };
    setSubmissions(prev => [...prev, submission]);
  }, []);

  const updateSubmissionStatus = useCallback((id: string, status: SubmissionStatus, notes?: string) => {
    setSubmissions(prev => prev.map(submission => {
      if (submission.id === id) {
        return {
          ...submission,
          status,
          reviewedAt: new Date().toISOString(),
          reviewNotes: notes,
        };
      }
      return submission;
    }));
  }, []);

  const getPendingSubmissions = useCallback(() => {
    return submissions.filter(s => s.status === 'pending');
  }, [submissions]);

  const getApprovedSubmissions = useCallback(() => {
    return submissions.filter(s => s.status === 'approved');
  }, [submissions]);

  return (
    <SubmissionsContext.Provider
      value={{
        submissions,
        addSubmission,
        updateSubmissionStatus,
        getPendingSubmissions,
        getApprovedSubmissions,
      }}
    >
      {children}
    </SubmissionsContext.Provider>
  );
}

export function useSubmissions() {
  const context = useContext(SubmissionsContext);
  if (context === undefined) {
    throw new Error('useSubmissions must be used within a SubmissionsProvider');
  }
  return context;
}
