import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft, Globe, CheckCircle } from 'lucide-react';
import { STARTUPS } from '../data/startups';
import { Button } from '../components/ui/Button';

export function StartupPage() {
  const { id } = useParams();
  const startup = STARTUPS.find(s => s.id === id);

  if (!startup) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900">Startup not found</h1>
          <Link to="/" className="text-indigo-600 hover:text-indigo-500 mt-4 inline-block">
            Return home
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <Link to="/" className="flex items-center text-gray-600 hover:text-gray-900 mb-8">
        <ArrowLeft className="h-5 w-5 mr-2" />
        Back to listings
      </Link>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
        <div className="space-y-8">
          <div className="flex items-center space-x-4">
            {startup.icon && (
              <img src={startup.icon} alt="" className="w-16 h-16 rounded-lg" />
            )}
            <div>
              <h1 className="text-3xl font-bold text-gray-900 flex items-center">
                {startup.title}
                {startup.isVerified && (
                  <CheckCircle className="h-6 w-6 text-blue-500 ml-2" />
                )}
              </h1>
              <a 
                href={`https://${startup.url}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-gray-700 flex items-center mt-2"
              >
                <Globe className="h-4 w-4 mr-2" />
                {startup.url}
              </a>
            </div>
          </div>

          <p className="text-lg text-gray-600">
            {startup.description}
          </p>

          <div className="flex space-x-4">
            <Button 
              variant="primary"
              onClick={() => window.open(`https://${startup.url}`, '_blank')}
            >
              Visit Website
            </Button>
            <Button variant="outline">Share</Button>
          </div>
        </div>

        <div>
          <div className="aspect-video rounded-lg overflow-hidden">
            <img 
              src={startup.image} 
              alt={startup.title}
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
}