import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Header } from './components/layout/Header';
import { HomePage } from './pages/HomePage';
import { StartupPage } from './pages/StartupPage';
import { SubmitPage } from './pages/SubmitPage';
import { SubmitSuccessPage } from './pages/SubmitSuccessPage';
import { AdminPage } from './pages/AdminPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { SubmissionsProvider } from './store/SubmissionsContext';

export default function App() {
  return (
    <HelmetProvider>
      <SubmissionsProvider>
        <Router>
          <div className="min-h-screen bg-gray-50">
            <Header />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/startup/:id" element={<StartupPage />} />
              <Route path="/submit" element={<SubmitPage />} />
              <Route path="/submit/success" element={<SubmitSuccessPage />} />
              <Route path="/admin" element={<AdminPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </Router>
      </SubmissionsProvider>
    </HelmetProvider>
  );
}