import React from 'react';
import { Rocket, Trophy } from 'lucide-react';
import { Button } from '../ui/Button';

export function DirectoryFeature() {
  return (
    <div className="bg-gray-50 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-2xl shadow-sm p-8">
          <div className="flex items-center space-x-3 mb-4">
            <Rocket className="h-6 w-6 text-purple-500" />
            <Trophy className="h-6 w-6 text-yellow-500" />
          </div>
          <h2 className="text-3xl font-bold mb-4">
            Get Your Project Featured 🚀
          </h2>
          <p className="text-gray-600 mb-6">
            Submit your project to our curated directory and get noticed by thousands of potential users and investors.
          </p>
          <Button variant="primary">
            Submit Project
          </Button>
        </div>
      </div>
    </div>
  );
}