import React from 'react';
import { Link } from 'react-router-dom';
import { CheckCircle, Home } from 'lucide-react';
import { Button } from '../components/ui/Button';

export function SubmitSuccessPage() {
  return (
    <div className="min-h-[80vh] flex items-center justify-center">
      <div className="max-w-md mx-auto text-center px-4">
        <div className="mb-6 flex justify-center">
          <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center">
            <CheckCircle className="w-8 h-8 text-green-600" />
          </div>
        </div>
        <h1 className="text-3xl font-bold mb-4">Submission Received!</h1>
        <p className="text-gray-600 mb-8">
          Thank you for submitting your startup. Our team will review your submission
          and get back to you soon.
        </p>
        <div className="space-y-4">
          <Link to="/">
            <Button className="w-full">
              <Home className="w-4 h-4 mr-2" />
              Return to Home
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
