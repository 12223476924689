import React from 'react';
import { CheckCircle, ArrowUpRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Startup } from '../../types/startup';
import '../../styles/animations.css';

interface StartupCardProps extends Startup {}

export function StartupCard({ id, title, description, image, url, isVerified, icon }: StartupCardProps) {
  return (
    <Link 
      to={`/startup/${id}`}
      className="group block bg-white rounded-xl border border-gray-100 overflow-hidden card-hover-effect"
    >
      <div className="aspect-video relative overflow-hidden">
        <img 
          src={image} 
          alt={title} 
          className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105" 
        />
        {icon && (
          <div className="absolute bottom-4 left-4 bg-white rounded-xl p-2 shadow-lg">
            <img src={icon} alt={`${title} icon`} className="w-10 h-10 rounded-lg" />
          </div>
        )}
        {isVerified && (
          <div className="absolute top-4 right-4 bg-blue-500 text-white px-3 py-1 rounded-full text-sm font-medium flex items-center">
            <CheckCircle className="h-4 w-4 mr-1" />
            Verified
          </div>
        )}
      </div>
      <div className="p-6">
        <div className="flex items-center justify-between mb-3">
          <h3 className="text-xl font-bold text-gray-900 group-hover:text-purple-600 transition-colors">
            {title}
          </h3>
          <ArrowUpRight className="h-5 w-5 text-gray-400 group-hover:text-purple-600 transition-colors" />
        </div>
        <p className="text-gray-600 mb-4 line-clamp-2">
          {description}
        </p>
        <div className="flex items-center text-sm text-gray-500">
          <span className="inline-block px-3 py-1 bg-gray-50 rounded-full group-hover:bg-purple-50 group-hover:text-purple-600 transition-colors">
            {url.replace(/^https?:\/\//, '')}
          </span>
        </div>
      </div>
    </Link>
  );
}