import React, { useState } from 'react';
import { useSubmissions } from '../store/SubmissionsContext';
import { StartupSubmission } from '../types/submission';
import { Button } from '../components/ui/Button';
import { CheckCircle, XCircle, Clock } from 'lucide-react';

export function AdminPage() {
  const { getPendingSubmissions, updateSubmissionStatus } = useSubmissions();
  const [selectedSubmission, setSelectedSubmission] = useState<StartupSubmission | null>(null);
  const [reviewNotes, setReviewNotes] = useState('');

  const pendingSubmissions = getPendingSubmissions();

  const handleApprove = () => {
    if (selectedSubmission) {
      updateSubmissionStatus(selectedSubmission.id, 'approved', reviewNotes);
      setSelectedSubmission(null);
      setReviewNotes('');
    }
  };

  const handleReject = () => {
    if (selectedSubmission) {
      updateSubmissionStatus(selectedSubmission.id, 'rejected', reviewNotes);
      setSelectedSubmission(null);
      setReviewNotes('');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Admin Review</h1>
      
      <div className="grid md:grid-cols-2 gap-8">
        {/* Submissions List */}
        <div className="space-y-4">
          <h2 className="text-xl font-semibold mb-4">Pending Submissions</h2>
          {pendingSubmissions.length === 0 ? (
            <p className="text-gray-500">No pending submissions</p>
          ) : (
            pendingSubmissions.map(submission => (
              <div
                key={submission.id}
                className={`p-4 border rounded-lg cursor-pointer transition-colors ${
                  selectedSubmission?.id === submission.id
                    ? 'border-purple-500 bg-purple-50'
                    : 'hover:border-gray-300'
                }`}
                onClick={() => setSelectedSubmission(submission)}
              >
                <div className="flex items-center justify-between mb-2">
                  <h3 className="font-semibold">{submission.title}</h3>
                  <Clock className="w-5 h-5 text-yellow-500" />
                </div>
                <p className="text-sm text-gray-600 mb-2">{submission.url}</p>
                <p className="text-sm text-gray-500">
                  Submitted: {new Date(submission.submittedAt).toLocaleDateString()}
                </p>
              </div>
            ))
          )}
        </div>

        {/* Review Panel */}
        {selectedSubmission && (
          <div className="border rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4">Review Submission</h2>
            
            <div className="space-y-4 mb-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Startup Name
                </label>
                <p className="text-lg">{selectedSubmission.title}</p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Description
                </label>
                <p className="text-gray-600">{selectedSubmission.description}</p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Website
                </label>
                <a
                  href={selectedSubmission.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-purple-600 hover:underline"
                >
                  {selectedSubmission.url}
                </a>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Preview
                </label>
                <div className="aspect-video relative rounded-lg overflow-hidden bg-gray-100">
                  <img
                    src={selectedSubmission.image}
                    alt={selectedSubmission.title}
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Review Notes
                </label>
                <textarea
                  value={reviewNotes}
                  onChange={(e) => setReviewNotes(e.target.value)}
                  className="w-full rounded-lg border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                  rows={3}
                  placeholder="Add any notes about this submission..."
                />
              </div>
            </div>

            <div className="flex justify-end gap-4">
              <Button
                variant="outline"
                onClick={() => setSelectedSubmission(null)}
                className="border-gray-300"
              >
                Cancel
              </Button>
              <Button
                variant="outline"
                className="border-red-200 text-red-600 hover:bg-red-50"
                onClick={handleReject}
              >
                <XCircle className="w-4 h-4 mr-2" />
                Reject
              </Button>
              <Button onClick={handleApprove}>
                <CheckCircle className="w-4 h-4 mr-2" />
                Approve
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
