import React from 'react';
import { Sparkles } from 'lucide-react';
import { StartupCard } from './StartupCard';
import { FEATURED_STARTUPS } from '../../data/featured-startups';

export function FeaturedStartups() {
  return (
    <div className="bg-gradient-to-b from-white to-gray-50 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center space-x-2 mb-8">
          <Sparkles className="h-6 w-6 text-yellow-500" />
          <h2 className="text-2xl font-bold text-gray-900">Featured Projects</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {FEATURED_STARTUPS.map((startup) => (
            <StartupCard key={startup.id} {...startup} />
          ))}
        </div>
      </div>
    </div>
  );
}