import React from 'react';
import { Button } from "../ui/Button"
import { Zap } from 'lucide-react'
import { Link } from 'react-router-dom';

export function Hero() {
  return (
    <div className="relative overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:w-full lg:pb-28 xl:pb-32">
          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="text-center">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block">Submit Your Startup to</span>
                <span className="block mt-2">
                  <span className="inline-flex items-center bg-[#9D6FFF] text-white px-4 py-2 rounded-full">
                    152+ Directories
                  </span>{" "}
                  Super Fast <Zap className="inline-block w-8 h-8 text-yellow-500" />
                </span>
                <span className="block mt-2">with Zero Hassle</span>
              </h1>
              
              <div className="mt-12 max-w-lg mx-auto">
                <p className="text-xl font-medium mb-6 text-gray-900">We will:</p>
                <ul className="space-y-4 text-lg text-gray-600">
                  <li className="flex items-center justify-center gap-2">
                    👉 Manually submit your startup to 100+ top directories & blogs
                  </li>
                  <li className="flex items-center justify-center gap-2">
                    🔗 Build high DA backlinks from sites like MSN and AP News
                  </li>
                  <li className="flex items-center justify-center gap-2">
                    🔥 Boost your startup's SEO domain authority
                  </li>
                  <li className="flex items-center justify-center gap-2">
                    🚀 Attract press coverage & early adopters
                  </li>
                </ul>
              </div>

              <div className="mt-12 flex flex-col sm:flex-row items-center justify-center gap-4">
                <Link to="/submit">
                  <Button size="lg" className="bg-[#0A0628] hover:bg-[#0A0628]/90 text-lg px-8 w-full sm:w-auto">
                    <Zap className="mr-2 h-5 w-5" /> Submit my startup
                  </Button>
                </Link>
                <div className="flex items-center border rounded-lg px-4 py-2 bg-white shadow-sm">
                  <img
                    src="/ph-logo.svg"
                    alt="Product Hunt"
                    className="w-10 h-10 mr-3"
                  />
                  <div className="text-left">
                    <div className="text-xs text-gray-500">FEATURED ON</div>
                    <div className="font-semibold">Product Hunt</div>
                  </div>
                  <div className="ml-4 bg-[#ff6154] text-white px-2 py-1 rounded text-sm">
                    531
                  </div>
                </div>
              </div>

              <div className="mt-16">
                <p className="text-lg text-gray-600 mb-8">As Seen In:</p>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-8 items-center justify-center max-w-4xl mx-auto opacity-80">
                  <img
                    src="/msn-logo.svg"
                    alt="MSN"
                    className="h-[50px] mx-auto"
                  />
                  <img
                    src="/harvard-logo.svg"
                    alt="Harvard University"
                    className="h-[50px] mx-auto"
                  />
                  <img
                    src="/ap-logo.svg"
                    alt="AP"
                    className="h-[50px] mx-auto"
                  />
                  <img
                    src="/stanford-logo.svg"
                    alt="Stanford"
                    className="h-[50px] mx-auto"
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      {/* Background gradient */}
      <div className="absolute inset-0 bg-gradient-to-b from-purple-50 to-white" style={{ zIndex: -1 }} />
    </div>
  );
}