import React from 'react';
import { Rocket } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Button } from '../ui/Button';

export function Header() {
  return (
    <header className="bg-white border-b border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex items-center justify-between">
          <Link to="/" className="flex items-center space-x-3">
            <Rocket className="h-8 w-8 text-purple-500" />
            <span className="text-xl font-bold">SubmitHunt</span>
          </Link>
          <nav className="hidden md:flex items-center space-x-6">
            <Link to="/" className="text-gray-600 hover:text-gray-900">Discover</Link>
            <Link to="/submit">
              <Button variant="primary">+ Submit Project</Button>
            </Link>
          </nav>
        </div>
      </div>
    </header>
  );
}