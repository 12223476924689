import React from 'react';
import { StartupCard } from './StartupCard';
import { STARTUPS } from '../../data/startups';

export function StartupGrid() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {STARTUPS.map((startup) => (
          <StartupCard key={startup.id} {...startup} />
        ))}
      </div>
    </div>
  );
}