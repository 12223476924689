import React from 'react';
import { Hero } from '../components/home/Hero';
import { FeaturedStartups } from '../components/startups/FeaturedStartups';
import { StartupGrid } from '../components/startups/StartupGrid';
import { DirectoryFeature } from '../components/features/DirectoryFeature';

export function HomePage() {
  return (
    <>
      <Hero />
      <FeaturedStartups />
      <StartupGrid />
      <DirectoryFeature />
    </>
  );
}