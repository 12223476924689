import { Startup } from '../types/startup';

export const STARTUPS: Startup[] = [
  {
    id: 'designflow',
    title: "DesignFlow",
    description: "AI-powered design tool that turns your ideas into beautiful websites in minutes.",
    image: "https://images.unsplash.com/photo-1542744094-3a31f272c490?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80",
    url: "designflow.ai",
    icon: "https://images.unsplash.com/photo-1633409361618-c73427e4e206?ixlib=rb-4.0.3&auto=format&fit=crop&w=100&q=80"
  },
  {
    id: 'contentai',
    title: "ContentAI",
    description: "Generate high-quality blog posts and articles with AI that ranks on Google.",
    image: "https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80",
    url: "contentai.app",
    isVerified: true
  },
  {
    id: 'pitchmaster',
    title: "PitchMaster",
    description: "Create stunning pitch decks with proven templates from successful startups.",
    image: "https://images.unsplash.com/photo-1553484771-047a44eee27f?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80",
    url: "pitchmaster.io"
  }
];